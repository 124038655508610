import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/components/landing/About.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/components/landing/CallToActionWithAnnotation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/components/landing/Contacts.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/components/landing/Function.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/components/landing/PricingTable.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Avatar"] */ "/vercel/path0/apps/web/components/ui/avatar.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/react/dist/esm/components/box/box.js");
;
import(/* webpackMode: "eager", webpackExports: ["Flex"] */ "/vercel/path0/node_modules/@chakra-ui/react/dist/esm/components/flex/flex.js");
;
import(/* webpackMode: "eager", webpackExports: ["Grid"] */ "/vercel/path0/node_modules/@chakra-ui/react/dist/esm/components/grid/grid.js");
;
import(/* webpackMode: "eager", webpackExports: ["Link"] */ "/vercel/path0/node_modules/@chakra-ui/react/dist/esm/components/link/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["Text"] */ "/vercel/path0/node_modules/@chakra-ui/react/dist/esm/components/typography/text.js");
